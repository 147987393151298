import React from 'react'
import { CoachingView } from '@views'

import { graphql } from 'gatsby'

import { Seo } from '@atoms'

export const Head = () => {
  return <Seo title={'Coaching'} />
}

const CoachingPage = (props) => {
  const data = props?.data?.contentfulCaseStudyGrid

  return <CoachingView data={data} />
}

export const coachingQuery = graphql`
  query coachingQuery {
    contentfulCaseStudyGrid(page: { eq: "Coaching" }) {
      ...CaseStudyGrid
    }
  }
`

export default CoachingPage
